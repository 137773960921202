//PROD

var HOST = "https://identity-internal-gateway.cloud.wso2.com/t/wso2internal928/certificationadminportal/";
// Stage
// var HOST =
//   "https://identity-internal-gateway.cloud.wso2.com/t/wso2internaldev/wso2cp/";

// Local Tomcat API Mapper
//var HOST = "http://localhost:8080/certportal/";

// API MAPPER PATH
var API_MAPPER_PATH = "api/";

var HOST_CONFIG = {
  // LOCAL BACK END DETAILS
  BACK_END_HOST: HOST,
  BACK_END_PORT: 8080,
  BACK_END_API_MAPPER_URL: HOST + API_MAPPER_PATH
};
// BASE PATH
var BASE_PATH = {
  MODULE_SERVICE_BASE_PATH: "Module/",
  PAPER_TYPE_SERVICE_BASE_PATH: "PaperType/",
  CATEGORY_SERVICE_BASE_PATH: "Category/",
  TOPIC_SERVICE_BASE_PATH: "Topic/",
  PAPER_SERVICE_BASE_PATH: "Paper/",
  QUESTION_SERVICE_BASE_PATH: "Question/",
  SCENARIO_SERVICE_BASE_PATH: "Scenario/",
  PRODUCT_SERVICE_BASE_PATH: "Product/",
  ANALYSE_SERVICE_BASE_PATH: "Analyse/",
  PAPER_SNAP_SERVICE_BASE_PATH: "PaperSnap/"
};

export const KEYWORDS = {
  NOT: "NOT",
  FALSE: "FALSE",
  CANNOT: "CANNOT",
  WRONG: "WRONG",
  INCORRECT : "INCORRECT"
};

export const EDITOR_HTML_TAGS = [
    'b', 'i', 'u', 'strong', 'em', 'p', 'br', 'div', 'span', 
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 
    'code', 'a', 'ul', 'ol', 'li', 'img', 'video', 'iframe', 
    'sub', 'sup', 'mark', 'del', 'ins', 'hr'
]

export default {
  HOST_CONFIG,
  BASE_PATH,
  KEYWORDS,
  EDITOR_HTML_TAGS
};
