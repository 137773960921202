import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import {sanitizeAndRenderHTML as renderHTML} from "../../../../../utils/utils";
import { AppConfig } from "../../../../../config/config.js";
import { ButtonGroup, Button, Tag, Classes } from "@blueprintjs/core";
import PRACTICALEditForm from "../../../Form/PracticalQuestion/EditForm/PRACTICALEditForm";

import "./zoom.css";
import withUseApiService from "../../../../../utils/withUseApiService.jsx";

class PracticalQuestionCard extends Component {
  constructor(props) {
    super(props);
    this.apiService = this.props.apiService;
    this.state = {
      EditMode: false,

      imageData: this.props.data.otherContent,
    };
    this.toggleMode = () => {
      this.setState({
        EditMode: !this.state.EditMode,
      });
    };
  }

  QuestionDelete = async (ID) => {
    this.apiService.getApiInstance()
      .patch(AppConfig.serviceUrls.question + this.props.data.id + '/activation'
      )
      .then((res) => {
        if (res.status === 200) {
          //this.toggleMode();
          this.props.FetchQuestionData(0);
          this.props.Notification(
            "tr",
            "error",
            "Question (Question ID: " +
              this.props.data.id +
              ") Removed successfully",
            "pe-7s-speaker"
          );
        }
        if (res.status === 400) {
          console.log("Bad", res);
        }
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  // Handle Data

  render() {
    let loading = this.props.Loading;
    let LoadingStyle = loading ? Classes.SKELETON : "";
    const htmlContent = this.props.data.content;
    return (
      <div>
        <PRACTICALEditForm
          onClose={this.toggleMode}
          isOpen={this.state.EditMode}
          data={this.props.data}
          {...this.props}
        />
        <Col sm={12} md={12}>
          <div autoFocus className={"card"} style={{ width: "100%" }}>
            <div className="content">
              <Row>
                <Col sm={12} md={1}>
                  <h3
                    className={LoadingStyle}
                    style={{ padding: "  20px", color: "#474747" }}
                  >
                    {("0" + parseInt(this.props.index + 1, 10)).slice(-2)}
                  </h3>
                </Col>
                <Col sm={12} md={11}>
                  <Row>
                    <Col sm={12}>
                      {/* Question ToolBar------------------- */}
                      <ButtonGroup minimal={true} className={LoadingStyle}>
                        <Button icon="edit" onClick={this.toggleMode}>
                          Edit
                        </Button>
                        <Button icon="flow-branch">Clone</Button>
                        <Button icon="trash" intent="danger">
                          Remove
                        </Button>
                      </ButtonGroup>
                      {/* End Question Tool Bar ------------------- */}
                    </Col>
                    <br />
                    <hr />
                    <Col sm={12} className={LoadingStyle}>
                      {renderHTML(htmlContent)}
                    </Col>
                  </Row>

                  {this.props.data.otherContent !== null && (
                    <Row>
                      <img
                        className={LoadingStyle + " zoom"}
                        style={{
                          border: "1px solid #ddd",
                          border_radius: "4px",
                          width: "100px",
                        }}
                        alt="Question Other Content"
                        src={this.props.data.otherContent}
                      />
                    </Row>
                  )}

                  <Row>
                    <hr />
                    <Col sm={12} style={{ paddingTop: "4px" }}>
                      <b>Compatible Versions </b> :
                      {this.props.data.versionList.split(",").map(
                        (value, index) => {
                          return (
                            <Tag
                              style={{ marginRight: "3px" }}
                              minimal={true}
                              intent="success"
                              key={index}
                            >
                              {value}
                            </Tag>
                          );
                        }
                      )}
                    </Col>
                    <Col sm={6}>
                      <Row>
                        <Col md={6} sm={12} className={LoadingStyle} style={{ paddingTop: "4px" }}>
                          <b>Created By</b> :{this.props.data.createdBy}
                        </Col>
                        <Col md={6} sm={12} className={LoadingStyle} style={{ paddingTop: "4px" }}>
                          <b>Date Created</b> :{this.props.data.createdDate}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={12} className={LoadingStyle} style={{ paddingTop: "4px" }}>
                          <b>Verified By</b> :{this.props.data.verifiedBy}
                        </Col>
                        <Col md={6} sm={12} className={LoadingStyle} style={{ paddingTop: "4px" }}>
                          <b>Date Verified </b> :{this.props.data.verifiedDate}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={12} className={LoadingStyle}>
                          {this.props.data.isSample === 1 && (
                            <Tag intent="success">Sample Question</Tag>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={6}>
                      <Row>
                        <p className="category">
                          <b> ID </b>:{this.props.data.id}
                        </p>
                        <p className="category">
                          {" "}
                          <b>Category ID</b> :{this.props.data.categoryId}
                        </p>
                        <p className="category">
                          {" "}
                          <b>Topic ID</b> :{this.props.data.topicId}
                        </p>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </div>
    );
  }
}

export default withUseApiService(PracticalQuestionCard);
